import React from "react"
import Styles from "./wrapper.module.scss"

const Wrapper = props => {
  return (
    <div
      className={`
      ${Styles.wrapper} 
      ${props.flex ? Styles.wrapperFlex : ""}
      ${props.noMaxWidth ? Styles.wrapperNoMaxWidth : ""}
      ${props.noPadding ? Styles.wrapperNoPadding : ""}
      ${props.center ? Styles.wrapperCenter : ""}`}
    >
      {props.children}
    </div>
  )
}

export default Wrapper
