import React from "react"
import PropTypes from "prop-types"
import Styles from "./title.module.scss"
import cn from "classnames"

const Title = ({ tag: Tag, ...props }) => {
  const modifier = Tag.toUpperCase()
  const colorVariable = props.color
    ? props.color.charAt(0).toUpperCase() + props.color.slice(1)
    : false

  return (
    <Tag
      dangerouslySetInnerHTML={{ __html: props.title }}
      className={cn(
        Styles.title,
        modifier && Styles[`title${modifier}`],
        Styles[`title${colorVariable}`]
      )}
    />
  )
}

Title.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
}

Title.defaultProps = {
  title: "",
  modifier: false,
}

export default Title
