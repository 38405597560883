import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import cn from "classnames"
import Styles from "./button.module.scss"

const Icon = ({ path }) => {
  if (!path || !path.length) {
    return null
  }

  return <img alt="Icon" className={Styles.Button__icon} src={path} />
}

const Button = ({ children, ...props }) => {
  if (props.element === "anchor") {
    const attrs =
      props.destination === "internal"
        ? {
            to: props.link,
          }
        : {
            href: props.link,
            target: props.target,
            rel: "noopener",
          }
    if (props.destination === "internal") {
      return (
        <Link
          className={cn(
            Styles.Button,
            props.type === "Normal" ? Styles.ButtonNormal : Styles.ButtonSolid
          )}
          data-theme={props.theme}
          {...attrs}
        >
          <Icon path={props.icon} />
          {children}
        </Link>
      )
    }

    return (
      <a
        className={cn(
          Styles.Button,
          props.type === "Normal" ? Styles.ButtonNormal : Styles.ButtonSolid
        )}
        data-theme={props.theme}
        {...attrs}
      >
        <Icon path={props.icon} />
        {children}
      </a>
    )
  }

  return (
    <button
      className={`${Styles.Button} ${
        props.type !== "Normal" ? Styles[`Button${props.type}`] : ""
      }`}
      data-theme={props.theme}
      type={props.buttonType}
      onClick={props.onClick && !props.disabled ? props.onClick : null}
    >
      <Icon path={props.icon} />
      {children}
    </button>
  )
}

Button.propTypes = {
  element: PropTypes.oneOf(["anchor", "button"]),
  link: PropTypes.string,
  type: PropTypes.oneOf(["Solid", "Normal"]),
  destination: PropTypes.oneOf(["internal", "external"]),
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  theme: PropTypes.oneOf(["Default", "Dark", "Light"]),
  icon: PropTypes.string,
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  element: "anchor",
  link: "/",
  type: "Normal",
  destination: "internal",
  target: "_self",
  theme: "Default",
  icon: "",
  buttonType: "text",
  disabled: false,
}

export default Button
