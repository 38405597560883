export function getPageUrl(link) {
  const isWebLink = link?._linkType === "Link.web" && link.url
  if (isWebLink) return link.url

  const parent = link.parent ? link.parent._meta.uid : ""
  const slug = link._meta.uid
  const url = parent ? `/${parent}/${slug}` : `/${slug}`

  return url
}
