import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import cn from "classnames"
import { getPageUrl } from "../../utils/helpers"
import Button from "../button/button"
import NavigationDropdown from "../navigation-dropdown/navigation-dropdown"
import Styles from "./navigation.module.scss"
import Burger from "../burger/burger"

const ToggleDropdown = ({ items }) => {
  const parents = items.filter(item => item.primary).map(item => item.primary)

  return (
    <div className={`${Styles.navigation__dropdown}`}>
      <NavigationDropdown items={parents} />
    </div>
  )
}

const Dropdown = props => {
  if (!props.items.length) {
    return null
  }

  return (
    <div className={`${Styles.navigation__dropdown}`}>
      <NavigationDropdown items={props.items} />
    </div>
  )
}

const Navigation = ({ children, ...props }) => {
  const items = props.items.map((item, key) => {
    const { title, link, type } = item.primary
    const fields = item.fields

    if (!title) {
      return null
    }

    if (!link) {
      return (
        <li key={key} className={`${Styles.navigation__item}`} tabIndex="0">
          {title}
          <Dropdown items={fields} />
        </li>
      )
    }

    if (link._linkType === "Link.web") {
      if (type === "Button") {
        return (
          <li
            key={key}
            className={cn(
              Styles.navigation__item,
              Styles.navigation__itemButton
            )}
            tabIndex="0"
          >
            <Button link={link.url} target="_blank" destination="external">
              {title}
            </Button>
            <Dropdown items={fields} />
          </li>
        )
      }

      return (
        <li key={key} className={`${Styles.navigation__item}`} tabIndex="0">
          <a
            className={`${Styles.navigation__link}`}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
          <Dropdown items={fields} />
        </li>
      )
    }

    if (type === "Button") {
      return (
        <li
          key={key}
          className={cn(Styles.navigation__item, Styles.navigation__itemButton)}
        >
          <Button link={getPageUrl(link)}>{title}</Button>
          <Dropdown items={fields} />
        </li>
      )
    }

    return (
      <li key={key} className={`${Styles.navigation__item}`} tabIndex="0">
        <Link
          className={`${Styles.navigation__link}`}
          activeClassName={Styles.navigation__linkSelected}
          to={getPageUrl(link)}
        >
          {title}
        </Link>
        <Dropdown items={fields} />
      </li>
    )
  })

  return (
    <nav className={Styles.navigation}>
      <div
        className={`${Styles.navigation__link} ${Styles.navigation__toggle}`}
        tabIndex="0"
        role="button"
      >
        <Burger />
        <ToggleDropdown items={props.items} />
      </div>
      <ul className={`${Styles.navigation__list}`}>{items}</ul>
    </nav>
  )
}

Navigation.propTypes = {
  items: PropTypes.array,
}

Navigation.defaultProps = {
  items: [],
}

export default Navigation
