import React from "react"
import PropTypes from "prop-types"
import "./layout.module.scss"

const Layout = props => {
  return (
    <>
      {props.header}
      {props.body}
      {props.footer}
    </>
  )
}

Layout.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
}

export default Layout
