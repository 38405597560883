import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Wrapper from "../layout/wrapper/wrapper"
import Logo from "../logo/logo"
import Styles from "./footer.module.scss"

const Footer = props => {
  return (
    <footer className={Styles.footer}>
      <Wrapper center className={Styles.footer__wrapper}>
        <div className={Styles.footer__container}>
          <div className={Styles.footer__navigation}>{props.navigation}</div>
          <div className={Styles.footer__newsletter}>{props.newsletter}</div>
        </div>

        <span className={Styles.footer__logos}>
          <img src={"/footer-logos/iso-27001.svg"} alt={"ISO 27001"} />
          <img
            className={Styles.footer__logos__ce}
            src={"/footer-logos/cyber-essentials.svg"}
            alt={"Cyber essentials"}
          />

          <a href="https://www.capterra.com/p/159104/CaptionHub/reviews/"> <img border="0" src="https://brand-assets.capterra.com/badge/85447660-5472-4605-a234-3c3695894568.svg" /> </a>
          <a href="https://www.getapp.com/collaboration-software/a/captionhub/reviews/ "> <img border="0" src="https://brand-assets.getapp.com/badge/47cee092-56f3-404b-baa4-2c8ebdc4e4ce.png" /> </a>

        </span>
        <div className={Styles.footer__signoff}>
          <Link to="/" className={Styles.footer__logo}>
            <Logo />
          </Link>

          <p className={Styles.footer__copyright}>
            &copy; {new Date().getFullYear()} CaptionHub Ltd{" "}
            <Link to="/privacy-policy">Privacy policy</Link>
          </p>
        </div>
      </Wrapper>
    </footer>
  )
}

Footer.propTypes = {
  navigation: PropTypes.element,
  newsletter: PropTypes.element,
}

export default Footer
