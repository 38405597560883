import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { getPageUrl } from "../../utils/helpers"
import Styles from "./navigation-dropdown.module.scss"
import cn from "classnames"

const NavigationDropdown = props => {
  const items = props.items.map((item, key) => {
    const { title, link, overview, type } = item
    const highlightedLink = type === "Button"

    if (!title) {
      return null
    }

    if (!link) {
      return (
        <li key={key} className={`${Styles.navigationDropdown__item}`}>
          <p className={`${Styles.navigationDropdown__title}`}>{title}</p>
          <p className={`${Styles.navigationDropdown__overview}`}>{overview}</p>
        </li>
      )
    }

    if (link._linkType === "Link.web") {
      return (
        <li key={key} className={`${Styles.navigationDropdown__item}`}>
          <a
            className={`${Styles.navigationDropdown__link}`}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className={`${Styles.navigationDropdown__title}`}>{title}</p>
            <p className={`${Styles.navigationDropdown__overview}`}>
              {overview}
            </p>
          </a>
        </li>
      )
    }

    return (
      <li key={key} className={`${Styles.navigationDropdown__item}`}>
        <Link
          className={cn(
            Styles.navigationDropdown__link,
            highlightedLink && Styles.navigationDropdown__itemHighlighted
          )}
          activeClassName={Styles.navigationDropdown__linkSelected}
          to={getPageUrl(link)}
        >
          <p className={`${Styles.navigationDropdown__title}`}>{title}</p>
          <p className={`${Styles.navigationDropdown__overview}`}>{overview}</p>
        </Link>
      </li>
    )
  })

  return <ul className={`${Styles.navigationDropdown}`}>{items}</ul>
}

NavigationDropdown.propTypes = {
  items: PropTypes.array,
}

NavigationDropdown.defaultProps = {
  items: [],
}

export default NavigationDropdown
