import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import queryString from "query-string"

import Logo from "../logo/logo"
import Wrapper from "../layout/wrapper/wrapper"
import Styles from "./header.module.scss"
import SearchInput from "../search/search-input"

const Header = props => {
  const [active, setActive] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const navigation = props.showFooterHeader ? props.navigation : false
  function scrollHandler() {
    const position = window.scrollY >= 100
    setActive(position)
  }

  function cleanUp() {
    window.removeEventListener("scroll", scrollHandler)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    return cleanUp
  })

  return (
    <header className={`${Styles.header} ${active ? Styles.headerActive : ""}`}>
      <Wrapper flex center>
        <Link to="/" className={Styles.header__logo}>
          <Logo />
        </Link>
        {navigation}
        {props.showFooterHeader && (
          <SearchInput
            value={searchQuery}
            onSubmit={e => {
              e.preventDefault()
              navigate(
                `/search?${queryString.stringify({ query: searchQuery })}`
              )
            }}
            onChange={e => {
              setSearchQuery(e.target.value)
            }}
          />
        )}
      </Wrapper>
    </header>
  )
}

Header.propTypes = {
  navigation: PropTypes.element,
  showFooterHeader: PropTypes.bool,
}

export default Header
