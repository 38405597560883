import React, { Component } from "react"
import FormInput from "../form-input/form-input"
import Styles from "./newsletter.module.scss"
import Button from "../../components/button/button"
import isEmail from "validator/es/lib/isEmail"
import PropTypes from "prop-types"
class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.Newsletter = React.createRef()
    this.state = { email: "" }
  }
  handleSelectChange = selectedOption => {
    this.setState({ selectedOption, source: selectedOption.value })
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.Newsletter.current
    const { email } = this.state

    if (window.trackRoarEvent) {
      window.trackRoarEvent(email);
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(response => {
        this.setState({ submitted: true, error: false })
      })
      .catch(error => {
        this.setState({ submitted: false, error: true })

        console.log(`error in submiting the form data:${error}`)
      })
  }
  render() {
    const { error, submitted, email } = this.state
    const { subscriptionSuccessMessage, subscriptionErrorMessage } = this.props
    return (
      <div className={Styles.newsletter}>
        {!submitted && (
          <h3 className={Styles.newsletter__headline}>
            Subscribe to our newsletter
          </h3>
        )}

        <form
          className={Styles.form}
          name="subscribe"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          ref={this.Newsletter}
        >
          <div className={Styles.newsletter__fields}>
            {!submitted && (
              <>
                <input type="hidden" name="form-name" value="subscribe" />
                <div hidden>
                  <label htmlFor={"bot-field"}>
                    Don’t fill this out:{" "}
                    <input
                      name="bot-field"
                      onChange={this.handleChange}
                      className={Styles.input}
                    />
                  </label>
                </div>
                <FormInput
                  label="Enter your email"
                  id="newsletter-email"
                  type="email"
                  name={"email"}
                  placeholder="Enter your email"
                  defaultValue={""}
                  required={true}
                  onChange={this.handleChange}
                />

                <p className={Styles.newsletter__terms}>
                  By clicking the Submit button you are agreeing to receive
                  email communications from us from time to time. We will not
                  share your details with any 3rd parties. Please review our
                  Privacy Policy for more information.
                </p>

                <Button
                  element="button"
                  theme="Light"
                  disabled={!isEmail(email)}
                >
                  Submit
                </Button>
              </>
            )}
            {error && <p>{subscriptionErrorMessage}</p>}
            {submitted && <p>{subscriptionSuccessMessage}</p>}
          </div>
        </form>
      </div>
    )
  }
}

Newsletter.propTypes = {
  subscriptionSuccessMessage: PropTypes.string,
  subscriptionErrorMessage: PropTypes.string,
}

Newsletter.defaultProps = {
  subscriptionSuccessMessage:
    "Thanks, you have subscribed to our mailing list.",
  subscriptionErrorMessage: "Something went wrong. Please try again.",
}
export default Newsletter
