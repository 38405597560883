import React from "react"
import PropTypes from "prop-types"
import Styles from "./form-input.module.scss"

const FormInput = props => {
  return (
    <div className={Styles.formInput}>
      <label className={Styles.formInput__label} htmlFor={props.id}>
        {props.label}
      </label>
      <input
        required={props.required}
        className={Styles.formInput__input}
        id={props.id}
        type={props.type}
        {...props}
      />
    </div>
  )
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(["Dark", "Light"]),
  type: PropTypes.oneOf(["text", "email", "password", "tel"]),
  required: PropTypes.bool,
}

FormInput.defaultProps = {
  id: "",
  label: "",
  theme: "Dark",
  type: "text",
  required: false,
}

export default FormInput
