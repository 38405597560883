import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

import Styles from "./search-input.module.scss"

const SearchInput = ({ onSubmit = () => {}, ...rest }) => {
  return (
    <form className={Styles.searchInput} onSubmit={onSubmit}>
      <input type="search" placeholder="Search" aria-label="Search" {...rest} />
    </form>
  )
}

export const ConnectedSearchInput = connectSearchBox(({ refine, ...rest }) => (
  <SearchInput onChange={e => refine(e.target.value)} />
))

export default SearchInput
