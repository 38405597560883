import React from "react"

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.786 37.086">
    <path fill="none" d="M0 0h139.786v37.086H0z" />
    <g>
      <path d="M0 18.213h92.685V0H0z" fill="#ebebeb" />
      <path d="M96.695 37.438h12.015v-18.1H96.695z" fill="red" />
      <path d="M112.136 37.438h11.567v-18.1h-11.567z" fill="#0f0" />
      <path d="M127.802 37.438h12.387v-18.1h-12.387z" fill="#00d1ff" />
    </g>
  </svg>
)

export default Logo
