import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { getPageUrl } from "../../utils/helpers"
import Styles from "./lists.module.scss"

const Lists = ({ children, ...props }) => {
  const menus = props.items.map((menu, menuKey) => {
    const fields = menu.fields.map((item, itemKey) => {
      if (!item.link) {
        return (
          <li key={itemKey} className={`${Styles.lists__item}`}>
            <span className={item.indented ? Styles.indented : null}>
              {item.title}
            </span>
          </li>
        )
      }

      if (item.link._linkType === "Link.web") {
        return (
          <li key={itemKey} className={`${Styles.lists__item}`}>
            <span className={item.indented ? Styles.indented : null}>
              <a
                className={`${Styles.lists__link}`}
                href={item.link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </span>
          </li>
        )
      }

      return (
        <li key={itemKey} className={`${Styles.lists__item}`}>
          <Link
            className={`${Styles.lists__link}`}
            activeClassName={Styles.lists__linkSelected}
            to={getPageUrl(item.link)}
          >
            <span className={item.indented ? Styles.indented : null}>
              {item.title}
            </span>
          </Link>
        </li>
      )
    })

    return (
      <div key={menuKey} className={`${Styles.lists__menu}`}>
        <h6 className={`${Styles.lists__title}`}>{menu.primary.title}</h6>
        <ul className={`${Styles.lists__list}`}>{fields}</ul>
      </div>
    )
  })

  return <nav className={Styles.lists}>{menus}</nav>
}

Lists.propTypes = {
  items: PropTypes.array,
}

Lists.defaultProps = {
  items: [],
}

export default Lists
